<template>
  <svg
    viewBox="0 0 26 45"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.23224 24.092C0.255934 23.1157 0.255934 21.5328 1.23224 20.5565L21.5564 0.232317L25.0919 3.76785L6.53555 22.3242L25.0919 40.8806L21.5564 44.4161L1.23224 24.092Z"
      :fill="disabled ? '#cccccc' :'#303448'"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowLeft",
  props:{
    disabled:{
      type: Boolean,
      default : false
    }
  }
};
</script>
