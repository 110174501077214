<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
      d="M26.73,18.3a7.61,7.61,0,0,1,3.91-6.92,5,5,0,0,1,.58-.3l.36-.16a.15.15,0,0,1,.09,0,.23.23,0,0,1,.11,0,.19.19,0,0,1,.09.17v4.77c0,1.47.6,2.07,2.07,2.08h1.51a1.67,1.67,0,0,0,1.77-1.79c0-1.12,0-2.23,0-3.46V10.91a.23.23,0,0,1,.11-.18l.09,0a.21.21,0,0,1,.12,0c.31.23.61.44.94.66a10.31,10.31,0,0,1,1.64,1.27,7.81,7.81,0,0,1-1.88,12.37l0,0h0l-.06,0-.58.35.09.67,0,5.79H31.47l0-5.93V25.4l-.52-.28A7.68,7.68,0,0,1,26.73,18.3Zm13.59,37H35.81l-3.4,0a9.07,9.07,0,0,1-9.07-8.48,28.59,28.59,0,0,1,.26-6.86,8.75,8.75,0,0,1,8.28-7l4.15,0h4.36A2.17,2.17,0,0,1,42.74,35a2.2,2.2,0,0,1-2.36,2.13H33.86c-.64,0-1.27,0-1.24.91s.63.83,1.23.83h3.71c1,0,1.93,0,2.9,0a2.1,2.1,0,0,1,2.21,1.7,1.93,1.93,0,0,1-1,2.23,2.84,2.84,0,0,1-1.36.28c-.92,0-1.85,0-2.77,0H33.72c-.65,0-1.14.24-1.11,1s.5.86,1.1.86h0l5.74,0H40a4.76,4.76,0,0,1,1,.07,2.15,2.15,0,0,1,1.72,2.21,2.11,2.11,0,0,1-2.09,1.95H34c-.63,0-1.31,0-1.34.86s.63.95,1.3.95h6.44a2.14,2.14,0,1,1,0,4.24Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Modulmontage",
  mixins:[SCGMixin],
};
</script>





