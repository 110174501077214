<template>
  
  <svg id="stromzaehler-icon" viewBox="0 0 62 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#F38D2F" d="M5.89096 69.2884H18.0504L18.0504 110.679C18.0504 115.947 24.1302 117.954 26.6071 113.689L61.0589 56.4949C63.5358 52.2304 60.8337 46.7116 56.3302 46.7116H44.1708L44.1708 5.32086C44.1708 0.0529513 38.091 -1.95387 35.6141 2.31063L0.937113 59.2543C-1.53982 63.5188 1.16228 69.2884 5.89096 69.2884Z" />
  </svg>

</template>

<script>
export default {
  name : "StromzaehlerIcon"
}
</script>