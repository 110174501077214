<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
     d="M27.1,21.1l0.7-1.5h3.8l-0.7,1.5H27.1z M31.6,19.6L31.6,19.6l-3.9-0.1L27,21.1h3.9L31.6,19.6z M27.8,19.6h3.7L30.8,21h-3.7
	L27.8,19.6z M20.2,27.8l-0.7,1.5h3.7l0.7-1.5H20.2z M24.1,27.8h-3.8l-0.7,1.5l0,0.1h3.8L24.1,27.8z M20.3,27.9h3.6l-0.6,1.4h-3.6
	L20.3,27.9z M27,21.1h-3.8l-3.1,6.7H24L27,21.1z M27.1,21.1L27.1,21.1L23.3,21l-3.1,6.8l0,0.1H24L27.1,21.1z M23.3,21.1H27l-3,6.6
	h-3.6L23.3,21.1z M27.7,19.6H24l-0.7,1.5H27L27.7,19.6z M27.8,19.5H24l-0.7,1.6l0,0.1h3.9L27.8,19.5z M24,19.6h3.6L27,21h-3.6
	L24,19.6z M23.3,29.3h3.8l0.7-1.5H24L23.3,29.3z M27.8,27.8L27.8,27.8L24,27.8l-0.7,1.6h3.9L27.8,27.8z M24,27.9h3.7l-0.6,1.4h-3.7
	L24,27.9z M24,27.8h3.8l3.1-6.7h-3.8L24,27.8z M30.9,21.1L30.9,21.1L27,21l-3.1,6.8l0,0.1h3.9L30.9,21.1z M27.1,21.1h3.7l-3,6.6
	h-3.7L27.1,21.1z M38.9,21.1l0.7-1.5h-3.8l-0.7,1.5H38.9z M39.6,19.6L39.6,19.6l-3.9-0.1L35,21.1h3.9L39.6,19.6z M35.8,19.6h3.7
	L38.8,21h-3.7L35.8,19.6z M28.3,27.8l-0.7,1.5h3.7l0.7-1.5H28.3z M32.1,27.8h-3.8l-0.7,1.5l0,0.1h3.8L32.1,27.8z M28.3,27.9h3.6
	l-0.6,1.4h-3.6L28.3,27.9z M35.1,21.1h-3.8l-3.1,6.7H32L35.1,21.1z M35.1,21.1L35.1,21.1L31.3,21l-3.1,6.8l0,0.1H32L35.1,21.1z
	 M31.3,21.1H35l-3,6.6h-3.6L31.3,21.1z M35.8,19.6H32l-0.7,1.5h3.8L35.8,19.6z M35.8,19.5H32l-0.7,1.6l0,0.1h3.9L35.8,19.5z
	 M32,19.6h3.6L35,21h-3.6L32,19.6z M31.3,29.3h3.8l0.7-1.5H32L31.3,29.3z M35.9,27.8L35.9,27.8L32,27.8l-0.7,1.6h3.9L35.9,27.8z
	 M32,27.9h3.7l-0.6,1.4h-3.7L32,27.9z M32,27.8h3.8l3.1-6.7h-3.8L32,27.8z M38.9,21.1L38.9,21.1L35.1,21L32,27.8l0,0.1h3.9
	L38.9,21.1z M35.1,21.1h3.7l-3,6.6h-3.7L35.1,21.1z M46.9,21.1l0.7-1.5h-3.8l-0.7,1.5H46.9z M47.6,19.6L47.6,19.6l-3.9-0.1L43,21.1
	h3.9L47.6,19.6z M43.8,19.6h3.7L46.9,21h-3.7L43.8,19.6z M36.3,27.8l-0.7,1.5h3.7l0.7-1.5H36.3z M40.1,27.8h-3.8l-0.7,1.5l0,0.1h3.8
	L40.1,27.8z M36.3,27.9h3.6l-0.6,1.4h-3.6L36.3,27.9z M43.1,21.1h-3.8l-3.1,6.7H40L43.1,21.1z M43.1,21.1L43.1,21.1L39.3,21
	l-3.1,6.8l0,0.1H40L43.1,21.1z M39.4,21.1H43l-3,6.6h-3.6L39.4,21.1z M43.8,19.6H40l-0.7,1.5h3.8L43.8,19.6z M43.9,19.5H40l-0.7,1.6
	l0,0.1h3.9L43.9,19.5z M40.1,19.6h3.6L43.1,21h-3.6L40.1,19.6z M39.3,29.3h3.8l0.7-1.5H40L39.3,29.3z M43.9,27.8L43.9,27.8L40,27.8
	l-0.7,1.6h3.9L43.9,27.8z M40.1,27.9h3.7l-0.6,1.4h-3.7L40.1,27.9z M40,27.8h3.8l3.1-6.7h-3.8L40,27.8z M46.9,21.1L46.9,21.1
	L43.1,21L40,27.8l0,0.1h3.9L46.9,21.1z M43.1,21.1h3.7l-3,6.6h-3.7L43.1,21.1z M30.3,31.1l0.7-1.5h-3.8l-0.7,1.5H30.3z M31,29.6
	L31,29.6l-3.9-0.1l-0.7,1.6h3.9L31,29.6z M27.2,29.7h3.7l-0.6,1.4h-3.7L27.2,29.7z M19.7,37.9L19,39.4h3.7l0.7-1.5H19.7z M23.5,37.8
	h-3.8L19,39.4l0,0.1h3.8L23.5,37.8z M19.7,37.9h3.6l-0.6,1.4h-3.6L19.7,37.9z M26.5,31.1h-3.8l-3.1,6.7h3.7L26.5,31.1z M26.5,31.2
	L26.5,31.2l-3.8-0.1l-3.1,6.8l0,0.1h3.8L26.5,31.2z M22.8,31.2h3.6l-3,6.6h-3.6L22.8,31.2z M27.2,29.6h-3.8l-0.7,1.5h3.8L27.2,29.6z
	 M27.3,29.6h-3.9l-0.7,1.6l0,0.1h3.9L27.3,29.6z M23.5,29.7h3.6l-0.6,1.4h-3.6L23.5,29.7z M22.7,39.4h3.8l0.7-1.5h-3.8L22.7,39.4z
	 M27.3,37.9L27.3,37.9l-3.9-0.1l-0.7,1.6h3.9L27.3,37.9z M23.5,37.9h3.7l-0.6,1.4h-3.7L23.5,37.9z M23.4,37.9h3.8l3.1-6.7h-3.8
	L23.4,37.9z M30.3,31.2L30.3,31.2l-3.9-0.1l-3.1,6.8l0,0.1h3.9L30.3,31.2z M26.5,31.2h3.7l-3,6.6h-3.7L26.5,31.2z M38.3,31.1
	l0.7-1.5h-3.8l-0.7,1.5H38.3z M39.1,29.6L39.1,29.6l-3.9-0.1l-0.7,1.6h3.9L39.1,29.6z M35.3,29.7h3.7l-0.6,1.4h-3.7L35.3,29.7z
	 M27.7,37.9L27,39.4h3.7l0.7-1.5H27.7z M31.5,37.8h-3.8L27,39.4l0,0.1h3.8L31.5,37.8z M27.7,37.9h3.6l-0.6,1.4h-3.6L27.7,37.9z
	 M34.5,31.1h-3.8l-3.1,6.7h3.7L34.5,31.1z M34.6,31.2L34.6,31.2l-3.8-0.1l-3.1,6.8l0,0.1h3.8L34.6,31.2z M30.8,31.2h3.6l-3,6.6h-3.6
	L30.8,31.2z M35.2,29.6h-3.8l-0.7,1.5h3.8L35.2,29.6z M35.3,29.6h-3.9l-0.7,1.6l0,0.1h3.9L35.3,29.6z M31.5,29.7h3.6l-0.6,1.4h-3.6
	L31.5,29.7z M30.8,39.4h3.8l0.7-1.5h-3.8L30.8,39.4z M35.3,37.9L35.3,37.9l-3.9-0.1l-0.7,1.6h3.9L35.3,37.9z M31.5,37.9h3.7
	l-0.6,1.4h-3.7L31.5,37.9z M31.5,37.9h3.8l3.1-6.7h-3.8L31.5,37.9z M38.4,31.2L38.4,31.2l-3.9-0.1l-3.1,6.8l0,0.1h3.9L38.4,31.2z
	 M34.6,31.2h3.7l-3,6.6h-3.7L34.6,31.2z M46.3,31.1l0.7-1.5h-3.8l-0.7,1.5H46.3z M47.1,29.6L47.1,29.6l-3.9-0.1l-0.7,1.6h3.9
	L47.1,29.6z M43.3,29.7h3.7l-0.6,1.4h-3.7L43.3,29.7z M35.7,37.9L35,39.4h3.7l0.7-1.5H35.7z M39.5,37.8h-3.8L35,39.4l0,0.1h3.8
	L39.5,37.8z M35.7,37.9h3.6l-0.6,1.4h-3.6L35.7,37.9z M42.5,31.1h-3.8l-3.1,6.7h3.7L42.5,31.1z M42.6,31.2L42.6,31.2l-3.8-0.1
	l-3.1,6.8l0,0.1h3.8L42.6,31.2z M38.8,31.2h3.6l-3,6.6h-3.6L38.8,31.2z M43.2,29.6h-3.8l-0.7,1.5h3.8L43.2,29.6z M43.3,29.6h-3.9
	l-0.7,1.6l0,0.1h3.9L43.3,29.6z M39.5,29.7h3.6l-0.6,1.4h-3.6L39.5,29.7z M38.8,39.4h3.8l0.7-1.5h-3.8L38.8,39.4z M43.3,37.9
	L43.3,37.9l-3.9-0.1l-0.7,1.6h3.9L43.3,37.9z M39.5,37.9h3.7l-0.6,1.4h-3.7L39.5,37.9z M39.5,37.9h3.8l3.1-6.7h-3.8L39.5,37.9z
	 M46.4,31.2L46.4,31.2l-3.9-0.1l-3.1,6.8l0,0.1h3.9L46.4,31.2z M42.6,31.2h3.7l-3,6.6h-3.7L42.6,31.2z M29.7,41.2l0.7-1.5h-3.8
	l-0.7,1.5H29.7z M30.5,39.7L30.5,39.7l-3.9-0.1l-0.7,1.6h3.9L30.5,39.7z M26.7,39.7h3.7l-0.6,1.4H26L26.7,39.7z M19.1,48l-0.7,1.5
	h3.7l0.7-1.5H19.1z M22.9,47.9h-3.8l-0.7,1.5l0,0.1h3.8L22.9,47.9z M19.1,48h3.6l-0.6,1.4h-3.6L19.1,48z M25.9,41.2h-3.8L19.1,48
	h3.7L25.9,41.2z M26,41.2L26,41.2l-3.8-0.1l-3.1,6.8l0,0.1h3.8L26,41.2z M22.2,41.3h3.6l-3,6.6h-3.6L22.2,41.3z M26.6,39.7h-3.8
	l-0.7,1.5h3.8L26.6,39.7z M26.7,39.6h-3.9l-0.7,1.6l0,0.1h3.9L26.7,39.6z M22.9,39.7h3.6l-0.6,1.4h-3.6L22.9,39.7z M22.2,49.5H26
	l0.7-1.5h-3.8L22.2,49.5z M26.7,48L26.7,48l-3.9-0.1l-0.7,1.6H26L26.7,48z M22.9,48h3.7l-0.6,1.4h-3.7L22.9,48z M22.9,48h3.8
	l3.1-6.7h-3.8L22.9,48z M29.8,41.2L29.8,41.2l-3.9-0.1l-3.1,6.8l0,0.1h3.9L29.8,41.2z M26,41.3h3.7l-3,6.6h-3.7L26,41.3z M37.7,41.2
	l0.7-1.5h-3.8l-0.7,1.5H37.7z M38.5,39.7L38.5,39.7l-3.9-0.1l-0.7,1.6h3.9L38.5,39.7z M34.7,39.7h3.7l-0.6,1.4H34L34.7,39.7z
	 M27.1,48l-0.7,1.5h3.7l0.7-1.5H27.1z M30.9,47.9h-3.8l-0.7,1.5l0,0.1h3.8L30.9,47.9z M27.2,48h3.6l-0.6,1.4h-3.6L27.2,48z
	 M33.9,41.2h-3.8L27.1,48h3.7L33.9,41.2z M34,41.2L34,41.2l-3.8-0.1l-3.1,6.8l0,0.1h3.8L34,41.2z M30.2,41.3h3.6l-3,6.6h-3.6
	L30.2,41.3z M34.6,39.7h-3.8l-0.7,1.5h3.8L34.6,39.7z M34.7,39.6h-3.9l-0.7,1.6l0,0.1H34L34.7,39.6z M30.9,39.7h3.6l-0.6,1.4h-3.6
	L30.9,39.7z M30.2,49.5H34l0.7-1.5h-3.8L30.2,49.5z M34.7,48L34.7,48l-3.9-0.1l-0.7,1.6H34L34.7,48z M30.9,48h3.7L34,49.4h-3.7
	L30.9,48z M30.9,48h3.8l3.1-6.7h-3.8L30.9,48z M37.8,41.2L37.8,41.2l-3.9-0.1l-3.1,6.8l0,0.1h3.9L37.8,41.2z M34,41.3h3.7l-3,6.6H31
	L34,41.3z M45.8,41.2l0.7-1.5h-3.8L42,41.2H45.8z M46.5,39.7L46.5,39.7l-3.9-0.1l-0.7,1.6h3.9L46.5,39.7z M42.7,39.7h3.7l-0.6,1.4
	H42L42.7,39.7z M35.1,48l-0.7,1.5h3.7l0.7-1.5H35.1z M39,47.9h-3.8l-0.7,1.5l0,0.1h3.8L39,47.9z M35.2,48h3.6l-0.6,1.4h-3.6L35.2,48
	z M41.9,41.2h-3.8L35.1,48h3.7L41.9,41.2z M42,41.2L42,41.2l-3.8-0.1l-3.1,6.8l0,0.1h3.8L42,41.2z M38.2,41.3h3.6l-3,6.6h-3.6
	L38.2,41.3z M42.6,39.7h-3.8l-0.7,1.5h3.8L42.6,39.7z M42.7,39.6h-3.9l-0.7,1.6l0,0.1H42L42.7,39.6z M38.9,39.7h3.6l-0.6,1.4h-3.6
	L38.9,39.7z M38.2,49.5H42l0.7-1.5h-3.8L38.2,49.5z M42.7,48L42.7,48l-3.9-0.1l-0.7,1.6H42L42.7,48z M38.9,48h3.7L42,49.4h-3.7
	L38.9,48z M38.9,48h3.8l3.1-6.7H42L38.9,48z M45.8,41.2L45.8,41.2l-3.9-0.1l-3.1,6.8l0,0.1h3.9L45.8,41.2z M42,41.3h3.7l-3,6.6H39
	L42,41.3z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "PvZiegel",
  mixins:[SCGMixin],
};
</script>