<template>
  <svg viewBox="0 0 120 85" fill="none">
    <path d="M60 85L0.24424 0.250011L119.756 0.250001L60 85Z" fill="white"/>
  </svg>
</template>
<script>
export default {
  name: "ArrowDown",
};
</script>
