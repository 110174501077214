<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
       fill-rule="evenodd"
     d="M50,21V32.17H46.54V16.1A4.8,4.8,0,0,1,50,21ZM43.1,50h1.83V33.08H43.1ZM33.4,32.17h8.19V16H33.4ZM33.4,50h8.19V33.08H33.4Zm9.7-17.81h1.83V16H43.1ZM46.54,49.9A4.8,4.8,0,0,0,50,45V33.08H46.54ZM15.4,21V32.17h3.44V16.1A4.8,4.8,0,0,0,15.4,21Zm5,29h1.84V33.08H20.45ZM23.8,32.17H32V16H23.8ZM23.8,50H32V33.08H23.8ZM20.45,32.17h1.84V16H20.45ZM15.4,45a4.8,4.8,0,0,0,3.44,4.9V33.08H15.4Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "PvModule",
  mixins:[SCGMixin],
};
</script>