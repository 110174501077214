<template>
  <svg viewBox="0 0 120 85" fill="none">
    <path d="M60 0L119.756 84.75H0.244247L60 0Z" fill="white" />
  </svg>
</template>
<script>
export default {
  name: "ArrowUp",
};
</script>
