<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
       fill-rule="evenodd"
      d="M30.2,18h5.7v3.2h-5.7V18z M23.5,21.2h5.7V18h-5.7V21.2z M23.5,28.8h5.7v-7.1h-5.7V28.8z M23.5,36.4h5.7v-6.6
  h-5.7V36.4z M36.9,43.9h5.6v-6.6h-5.6V43.9z M30.2,43.9h5.7v-6.6h-5.7V43.9z M30.2,48h5.7v-3.5h-5.7V48z M23.5,43.9h5.7v-6.6h-5.7
  V43.9z M23.5,48h5.7v-3.5h-5.7V48z M36.9,48h5.6v-3.5h-5.6V48z M36.9,36.4h5.6v-6.6h-5.6V36.4z M30.2,36.4h5.7v-6.6h-5.7V36.4z
   M36.9,28.8h5.6v-7.1h-5.6V28.8z M30.2,28.8h5.7v-7.1h-5.7V28.8z M36.9,18v3.2h5.6V18H36.9z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "PvKlein",
  mixins:[SCGMixin],
};
</script>