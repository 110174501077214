<template>
  <svg viewBox="-20 -10 60 60" fill="none">
    <path
      d="M18.8571 3.66702L15.1323 3.66702L15.1323 0.716544C15.1323 0.295048 14.8219 1.35919e-08 14.4727 1.32717e-08L7.52734 6.90271e-09C7.13933 6.5469e-09 6.86772 0.337197 6.86772 0.716544L6.86772 3.66702L3.14286 3.66702C1.39682 3.66702 -9.75918e-08 5.1844 -1.33296e-07 7.08114L-6.88697e-07 36.5859C-7.24401e-07 38.4826 1.43562 40 3.14286 40L18.8571 40C20.6032 40 22 38.4826 22 36.5859L22 7.08114C22 5.1844 20.6032 3.66702 18.8571 3.66702ZM15.3263 23.7302L13.231 23.7302L13.231 30.6849C13.231 31.5701 12.1834 31.9073 11.7566 31.1907L5.8201 21.5806C5.3933 20.8641 5.8589 19.9368 6.63492 19.9368L8.73016 19.9368L8.73016 12.9821C8.73016 12.0969 9.77778 11.7597 10.2046 12.4763L16.1799 22.0443C16.6067 22.7608 16.1411 23.7302 15.3263 23.7302Z"
      :fill="GetState"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin";
export default { name: "Battery", mixins: [SCGMixin] };
</script>
