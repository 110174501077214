<template>
  <svg
    viewBox="0 0 27 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3.78428L0.100982 39.0755L7.67461 40L26.9495 39.7658V3.40216L8.4446 0L0 3.78428ZM7.46003 38.755L1.36325 37.9908L1.26227 4.59784L6.67742 2.2681L7.63675 1.82434L7.46003 38.755ZM25.7251 38.5455L8.97476 38.755L9.20197 1.77504L25.7251 4.40062V38.5455Z"
      :fill="GetState"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin";
export default { name: "NoFascade", mixins: [SCGMixin] };
</script>
