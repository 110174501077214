<template>
  <svg
    viewBox="0 0 26 40"
    fill="none"
  >
    <path
      d="M8.13182 0L0.024292 3.78428L0.121534 39.0755L7.41466 40L25.9757 39.7658V3.40216L8.13182 0ZM7.20802 38.755L1.33705 37.9908L1.23981 4.59784L6.4544 2.2681L7.3782 1.82434L7.20802 38.755Z"
      :fill="GetState"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin";
export default {
  name: "Fascade",
  mixins: [SCGMixin],
};
</script>
