<template>
  <svg viewBox="-10 -10 52 61" fill="none">
    <!-- <g clip-path="url(#clip0_2224_733)"> -->
      <path d="M5.39999 40.11H14.69V10.54H5.39999V40.11Z" :fill="GetState" />
      <path d="M16.63 40.1101H25.83V10.5701H16.63V40.1101Z" :fill="GetState" />
      <path
        d="M28.33 0.0200195C19.75 0.0200195 11.1667 0.0200195 2.58001 0.0200195C0.230012 0.0200195 -0.00998809 0.230019 1.19114e-05 2.55002C1.19114e-05 14.43 0.0800114 26.31 0.0600114 38.18C0.0600114 39.68 0.460011 40.42 2.06001 40.18C2.52001 40.11 3.00001 40.11 3.63001 40.11V38.11C3.63001 29.02 3.63001 19.93 3.63001 10.84C3.63001 9.26002 4.03001 8.68002 5.69001 8.71002C12.3567 8.79002 19.0467 8.79002 25.76 8.71002C27.29 8.71002 27.76 9.15002 27.76 10.71C27.63 19.87 27.63 29.01 27.63 38.16V40.11C31.17 40.4 31.17 40.4 31.18 37.21C31.18 25.7834 31.1967 14.3534 31.23 2.92002C31.27 0.11002 31.16 0.0200195 28.33 0.0200195ZM12.97 6.54002C12.8965 6.67144 12.7891 6.78068 12.6589 6.85633C12.5287 6.93198 12.3806 6.97125 12.23 6.97002H7.85001C7.69945 6.97125 7.55132 6.93198 7.42114 6.85633C7.29096 6.78068 7.1835 6.67144 7.11001 6.54002C7.0323 6.41365 6.9908 6.26837 6.99001 6.12002C6.98947 5.96835 7.03101 5.8195 7.11001 5.69002L9.30001 1.89002C9.38283 1.77038 9.49341 1.67261 9.62229 1.60508C9.75117 1.53755 9.89451 1.50227 10.04 1.50227C10.1855 1.50227 10.3288 1.53755 10.4577 1.60508C10.5866 1.67261 10.6972 1.77038 10.78 1.89002L12.97 5.69002C13.049 5.8195 13.0905 5.96835 13.09 6.12002C13.0892 6.26837 13.0477 6.41365 12.97 6.54002ZM24.16 3.16002L21.96 6.96002C21.8865 7.09144 21.7791 7.20069 21.6489 7.27633C21.5187 7.35198 21.3706 7.39125 21.22 7.39002C21.0699 7.38918 20.9225 7.34905 20.7927 7.27361C20.6629 7.19817 20.5551 7.09006 20.48 6.96002L18.29 3.16002C18.2182 3.02987 18.1806 2.88365 18.1806 2.73502C18.1806 2.58638 18.2182 2.44017 18.29 2.31002C18.3651 2.17998 18.4729 2.07187 18.6027 1.99643C18.7325 1.92099 18.8799 1.88086 19.03 1.88002H23.42C23.5698 1.8828 23.7163 1.92375 23.8458 1.999C23.9753 2.07424 24.0834 2.1813 24.16 2.31002C24.2318 2.44017 24.2694 2.58638 24.2694 2.73502C24.2694 2.88365 24.2318 3.02987 24.16 3.16002Z"
        :fill="GetState"
      />
    <!-- </g> -->
    <!-- <defs>
      <clipPath id="clip0_2224_733">
        <rect width="31.26" height="40.22" fill="white" />
      </clipPath>
    </defs> -->
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin";
export default {
  name: "Betriebsstrom",
  mixins: [SCGMixin],
};
</script>
