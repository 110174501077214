<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
     d="M58.55,36v0c-.62-2.1-1.14-3.78-2.74-4.91s-4-1.59-8.25-1.84l-7.46-4.66h0a10.92,10.92,0,0,0-6.32-2l-1.08,0-2.56-.05c-2,0-4.23-.08-5.38-.08a6.94,6.94,0,0,0-3.08,1.07,30.69,30.69,0,0,0-3.34,2.22c-1.06.8-2,1.6-2.74,2.19l-.83.73-.14.12H9.69a2.36,2.36,0,0,0-2.34,2.34v5.66A2.31,2.31,0,0,0,9.69,39.3h2.24a7.43,7.43,0,0,0,1.29,2.44,4.86,4.86,0,0,0,3.87,1.82,5.2,5.2,0,0,0,4.55-2.29,6.37,6.37,0,0,0,.88-2H44a5.76,5.76,0,0,0,1.27,2.46,5.21,5.21,0,0,0,4,1.8,5.58,5.58,0,0,0,4.18-1.79,6.09,6.09,0,0,0,1.39-2.47h1.57a2.11,2.11,0,0,0,2-1A3.32,3.32,0,0,0,58.55,36ZM36.14,31.82l-10,5v0l4.45-4.79L26.8,30.4v0l10-5.05v0l-4.46,4.8Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "EAutoTrue",
  mixins:[SCGMixin],
};
</script>