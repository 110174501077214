<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
      d="M16,17.4L32.4,25c0,0,0,0,0,0v30.3L16,45.5V17.4z M33,10.7l-16.2,6L33,24.1l16.2-7.4L33,10.7z M33.4,25v30.3L50,45.9V17.4
  L33.4,25z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Flach",
  mixins:[SCGMixin],
};
</script>
