<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
       fill-rule="evenodd"
      d="M49.2,13.4H16.8v36h0.4l0.1,0.2c0.9,2.5,1.3,3,3.9,3c0.5,0,1.1,0,1.8,0l0.2,0c1.8-0.1,2.2-1.3,2.5-2.2
  c0.1-0.2,0.1-0.5,0.2-0.7l0.1-0.3h14l0.1,0.2c0.9,2.5,1.3,3,3.9,3c0.5,0,1.1,0,1.8,0l0.2,0c1.8-0.1,2.2-1.3,2.5-2.2
  c0.1-0.2,0.1-0.5,0.2-0.7l0.1-0.3h0.4V13.4z M23.1,51.1l-0.2,0c-2.9,0.1-3.5,0.1-4-1h5.3C24,50.9,23.8,51.1,23.1,51.1z M46,51.1
  l-0.2,0c-2.9,0.1-3.5,0.1-4-1h5.3C46.8,50.9,46.7,51.1,46,51.1z M18.2,14.8h29.7V48H18.2V14.8z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Zelt",
  mixins:[SCGMixin],
};
</script>