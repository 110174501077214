<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
      d="M43.17,53.75H23.67a1.5,1.5,0,0,1,0-3h8V44.13a6.48,6.48,0,0,1-3.76-2.55,10.38,10.38,0,0,1-1-1.67l-.1-.21a14.09,14.09,0,0,1-5.25-4,5.75,5.75,0,0,1-1.09-4.63,7.85,7.85,0,0,1,1.69-3.38,11.83,11.83,0,0,1,1-1.08l.06-.07a6.85,6.85,0,0,1,1.56-6,7.62,7.62,0,0,1,1.76-1.47h0a8.76,8.76,0,0,1,1.11-3.82,6,6,0,0,1,2.64-2.4,6.81,6.81,0,0,1,2.52-.61,7.54,7.54,0,0,1,.83,0l.26,0H34a6.37,6.37,0,0,1,5.56,4.23A10,10,0,0,1,40.17,19c0,.11,0,.22,0,.32a7.76,7.76,0,0,1,3,5,8.92,8.92,0,0,1,.11,2.21c0,.07,0,.13,0,.2a9.24,9.24,0,0,1,2.33,5.55,6.57,6.57,0,0,1-1.8,4.57,11.47,11.47,0,0,1-3.13,2.4c-.46.25-.87.43-1.17.56l-.2.29A13.56,13.56,0,0,1,38,41.72,8.47,8.47,0,0,1,34.67,44v6.8h8.5a1.5,1.5,0,0,1,0,3Zm-19.5-2a.5.5,0,0,0,0,1h19.5a.5.5,0,0,0,0-1h-9.5V43.19l.38-.1A7.21,7.21,0,0,0,37.33,41a12.65,12.65,0,0,0,1.22-1.49l.27-.39L38.9,39l.28-.11c.27-.12.65-.29,1.09-.52a10.67,10.67,0,0,0,2.85-2.18,5.59,5.59,0,0,0,1.55-3.9,8.38,8.38,0,0,0-2.24-5.09L42.3,27l0-.22c0-.1,0-.23,0-.36a9,9,0,0,0-.1-2A6.88,6.88,0,0,0,39.44,20l-.19-.14,0-.23c0-.14,0-.3,0-.49a8.89,8.89,0,0,0-.59-2.2,5.4,5.4,0,0,0-4.74-3.61l-.13,0-.2,0a5.36,5.36,0,0,0-.7,0,5.71,5.71,0,0,0-2.16.52,5,5,0,0,0-2.2,2,8,8,0,0,0-1,3.63v.28l-.24.13L27,20a6.67,6.67,0,0,0-1.53,1.27,6,6,0,0,0-1.28,5.37l.06.26-.44.44c-.26.28-.58.62-.89,1a6.81,6.81,0,0,0-1.49,3,4.77,4.77,0,0,0,.92,3.85,13.51,13.51,0,0,0,5,3.77l.17.08.07.17c0,.1.1.21.16.34A9.54,9.54,0,0,0,28.71,41a5.37,5.37,0,0,0,3.53,2.23l.43.06v8.48Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "NoShadow",
  mixins:[SCGMixin],
};
</script>