<template>
  <svg viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
    
    <g @click="e => ChangeOrientation(Orientation.South)">
    <path class="south-bg" :fill="GetBGStyle(Orientation.South)" d="M21.3055 111.997C33.2578 123.635 49.2812 130.147 65.9636 130.147C82.646 130.147 98.6694 123.635 110.622 111.997L65.9636 67.3534L21.3055 111.997Z"/>
    <path class="arrow-south-bg" :fill="GetCharStyle(Orientation.South)" d="M65.8901 78.408H65.9779V78.6276V78.408H65.8901ZM67.8814 76.7827C67.2672 76.8885 66.6451 76.9423 66.0219 76.9438C65.3937 76.9423 64.7668 76.8884 64.1477 76.7827L66.0219 86.0658L67.8814 76.7827Z"/>
    <path class="south" :fill="GetCharStyle(Orientation.South)" d="M70.0659 98.2779H73.1912C73.0968 94.838 70.0554 92.3419 65.6926 92.3419C61.3822 92.3419 58.0786 94.8065 58.0786 98.5086C58.0786 101.498 60.2181 103.249 63.6685 104.182L66.2065 104.875C68.5033 105.483 70.2756 106.238 70.2756 108.147C70.2756 110.244 68.2725 111.629 65.5143 111.629C63.0183 111.629 60.9417 110.517 60.7529 108.178H57.5018C57.7116 112.069 60.7215 114.471 65.5353 114.471C70.5798 114.471 73.4848 111.817 73.4848 108.178C73.4848 104.308 70.0344 102.809 67.3077 102.137L65.2102 101.592C63.5321 101.162 61.2983 100.375 61.3088 98.3198C61.3088 96.495 62.9763 95.1421 65.6192 95.1421C68.0837 95.1421 69.8352 96.2957 70.0659 98.2779Z" />
    </g>


    <g @click="e => ChangeOrientation(Orientation.North)">
    <path class="north-bg" :fill="GetBGStyle(Orientation.North)" d="M110.605 19.9133C98.6522 8.2753 82.6288 1.76294 65.9464 1.76294C49.264 1.76294 33.2407 8.2753 21.2883 19.9133L65.9464 64.5568L110.605 19.9133Z"/>
    <path class="arrow-north-bg" :fill="GetCharStyle(Orientation.North)" d="M65.9635 53.2823V53.5019H65.8903V53.2823H65.9635ZM65.8903 45.8588L64.0161 55.1418C64.6349 55.0312 65.2618 54.9724 65.8903 54.9661C66.514 54.9724 67.136 55.0312 67.7498 55.1418L65.8903 45.8588V45.8588Z" />
    <path class="north-char" :fill="GetCharStyle(Orientation.North)" d="M74.2516 18.298H71.0425V34.0713H70.8432L59.9152 18.298H56.9157V39.7765H60.1564V24.0242H60.3556L71.2732 39.7765H74.2516V18.298Z" />
    </g>
    
    
    <g @click="e => ChangeOrientation(Orientation.East)">
    <path class="east-bg" :fill="GetBGStyle(Orientation.East)" d="M111.997 21.3042L67.3535 65.9624L111.997 110.606C123.635 98.6535 130.147 82.6301 130.147 65.9477C130.147 49.2653 123.635 33.2419 111.997 21.2896V21.3042Z" />
    <path class="arrow-east-bg" :fill="GetCharStyle(Orientation.East)" d="M78.4074 65.8891H78.627H78.4074ZM76.7821 64.0588C76.8879 64.678 76.9417 65.3049 76.9432 65.933C76.9417 66.5563 76.8879 67.1783 76.7821 67.7926L86.0652 65.933L76.7821 64.0588Z" />
    <path class="east-char" :fill="GetCharStyle(Orientation.East)" d="M115.267 66.3305C115.267 59.4612 111.156 55.2976 105.597 55.2976C100.018 55.2976 95.9171 59.4612 95.9171 66.3305C95.9171 73.1894 100.018 77.3634 105.597 77.3634C111.156 77.3634 115.267 73.1999 115.267 66.3305ZM112.057 66.3305C112.057 71.5638 109.289 74.4059 105.597 74.4059C101.895 74.4059 99.1368 71.5638 99.1368 66.3305C99.1368 61.0972 101.895 58.2551 105.597 58.2551C109.289 58.2551 112.057 61.0972 112.057 66.3305Z" />
    </g>
    
    
    <g @click="e => ChangeOrientation(Orientation.West)">
    <path class="west-bg" :fill="GetBGStyle(Orientation.West)" d="M19.9121 21.3042C8.27414 33.2566 1.76178 49.28 1.76178 65.9624C1.76178 82.6448 8.27414 98.6682 19.9121 110.621L64.5557 65.9624L19.9121 21.3042Z" />
    <path class="arrow-west-bg" :fill="GetCharStyle(Orientation.West)" d="M53.5026 65.8892V65.9771H53.2829H53.5026V65.8892ZM55.1425 64.1469L45.8594 66.021L55.1425 67.8806C55.0318 67.2667 54.9731 66.6447 54.9668 66.021C54.9731 65.3925 55.0318 64.7656 55.1425 64.1469V64.1469Z" />
    <path class="west-char" :fill="GetCharStyle(Orientation.West)" d="M18.9539 77.0697H22.226L26.7461 60.9818H26.9139L31.4341 77.0697H34.6957L40.6631 55.5912H37.2442L33.0701 72.2349H32.8709L28.5185 55.5912H25.1415L20.7892 72.2245H20.5899L16.4054 55.5912H12.9969L18.9539 77.0697Z" />
    </g>


    <path class="center-bg" fill="#fff" d="M65.9611 76.2265C71.6298 76.2265 76.2252 71.6311 76.2252 65.9624C76.2252 60.2937 71.6298 55.6984 65.9611 55.6984C60.2924 55.6984 55.6971 60.2937 55.6971 65.9624C55.6971 71.6311 60.2924 76.2265 65.9611 76.2265Z" />
    <path class="center-bg" fill="#303448" d="M65.9615 56.4305C67.8467 56.4305 69.6896 56.9896 71.2571 58.0369C72.8247 59.0843 74.0464 60.573 74.7678 62.3148C75.4893 64.0565 75.6781 65.9731 75.3103 67.8221C74.9425 69.6711 74.0346 71.3695 72.7016 72.7026C71.3685 74.0357 69.6701 74.9435 67.8211 75.3113C65.972 75.6791 64.0555 75.4903 62.3137 74.7689C60.572 74.0474 59.0833 72.8257 58.0359 71.2582C56.9885 69.6906 56.4295 67.8477 56.4295 65.9625C56.4295 63.4344 57.4338 61.01 59.2214 59.2224C61.0089 57.4348 63.4334 56.4305 65.9615 56.4305V56.4305ZM65.9615 54.9663C63.786 54.9634 61.6586 55.6059 59.8483 56.8124C58.0381 58.0189 56.6264 59.7352 55.7919 61.7443C54.9574 63.7533 54.7375 65.9647 55.1602 68.0987C55.5828 70.2327 56.6289 72.1934 58.1662 73.7327C59.7034 75.2721 61.6627 76.3208 63.7962 76.7463C65.9296 77.1717 68.1413 76.9548 70.1515 76.123C72.1616 75.2912 73.8798 73.8818 75.0888 72.0731C76.2977 70.2645 76.943 68.1379 76.943 65.9625C76.943 63.0486 75.7865 60.2539 73.7275 58.1922C71.6684 56.1304 68.8753 54.9702 65.9615 54.9663Z" />
    <path class="circle-bg" fill="#303448" fill-rule="evenodd" clip-rule="evenodd" d="M65.9639 2.50375C30.9167 2.50375 2.50531 30.9151 2.50531 65.9623C2.50531 101.009 30.9167 129.421 65.9639 129.421C101.011 129.421 129.422 101.009 129.422 65.9623C129.422 30.9151 101.011 2.50375 65.9639 2.50375ZM1.04111 65.9623C1.04111 30.1065 30.108 1.03955 65.9639 1.03955C101.82 1.03955 130.887 30.1065 130.887 65.9623C130.887 101.818 101.82 130.885 65.9639 130.885C30.108 130.885 1.04111 101.818 1.04111 65.9623Z" />
  </svg>

</template>

<script>
import { Orientation } from "../../Enum/Enums"


export default {
  name : "Orientation_UI",
  data(){
    return {
      Orientation,
      bg:{
        active : "#303448",
        inactive : "#fff"
      },
      char:{
        active : "#fff",
        inactive : "#303448"
      }
    }
  },
  mounted(){

  },
  computed:{
    },
  methods : {
    GetBGStyle(orientation){
      return orientation === this.$store.state.area.orientation ? this.bg.active : this.bg.inactive;
    },
    GetCharStyle(orientation){
      return orientation === this.$store.state.area.orientation ? this.char.active : this.char.inactive;
    },
    ChangeOrientation(orientation){
      
      //not present any more
      //this.$store.commit("SetOrientation" , orientation);
    }
  }
}
</script>

<style lang="scss" scope>
  g{
    cursor :pointer;
  }
</style>