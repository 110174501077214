<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
      d="M47.13,27.19a.5.5,0,0,0-.33-.55l-11.88-4-.45-4.55h7.62v3.51a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V18a.48.48,0,0,0,.28-.35.5.5,0,0,0-.32-.56l-9-3.34L33.5,8.23a.5.5,0,0,0-1,0L32,13.79l-9,3.32a.5.5,0,0,0-.32.56.49.49,0,0,0,.46.4v3.52a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V18.08h7.44l-.46,4.57-11.87,4a.5.5,0,0,0-.33.55.46.46,0,0,0,.23.34s0,0,0,.06v4a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-4h5v4a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-4h4.49l-3,30.07v0a.46.46,0,0,0,.13.33.47.47,0,0,0,.37.17h9.78a.47.47,0,0,0,.37-.17.46.46,0,0,0,.13-.33v0l-3-30.07h4.67v4a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-4h5v4a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-4a.45.45,0,0,0,0-.2A.44.44,0,0,0,47.13,27.19Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Netz",
  mixins:[SCGMixin],
};
</script>
