<template>
  <svg viewBox="-20 -10 60 60" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6323 5.16702L13.6323 1.5L8.36772 1.5L8.36772 5.16702L3.14286 5.16702C2.33975 5.16702 1.5 5.8935 1.5 7.08114L1.5 36.5859C1.5 37.7611 2.36703 38.5 3.14286 38.5L18.8571 38.5C19.6603 38.5 20.5 37.7735 20.5 36.5859L20.5 7.08114C20.5 5.8935 19.6603 5.16702 18.8571 5.16702L13.6323 5.16702ZM18.8571 3.66702C20.6032 3.66702 22 5.1844 22 7.08114L22 36.5859C22 38.4826 20.6032 40 18.8571 40L3.14286 40C1.43562 40 -7.24401e-07 38.4826 -6.88697e-07 36.5859L-1.33296e-07 7.08114C-9.75918e-08 5.1844 1.39682 3.66702 3.14286 3.66702L6.86772 3.66702L6.86772 0.716544C6.86772 0.337197 7.13933 6.5469e-09 7.52734 6.90271e-09L14.4727 1.32717e-08C14.8219 1.35919e-08 15.1323 0.295048 15.1323 0.716544L15.1323 3.66702L18.8571 3.66702Z"
      :fill="GetState"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin";
export default { name: "Battery", mixins: [SCGMixin] };
</script>
