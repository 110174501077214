<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
       fill-rule="evenodd"
    d="M32.1,35.2l-16.4-7.5c0,0,0,0,0,0l-0.4-0.2l9.6-12.3L32.1,35.2z M16,45.5l16.4,9.8V36.4L16,28.9V45.5z M33.4,55.3L50,45.9
  V28.9l-16.6,7.6V55.3z M40.2,10.7l-14.6,3.8l7.6,21l17-7.8c0,0,0,0,0,0l0.5-0.2L40.2,10.7z"/>
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Sattel",
  mixins:[SCGMixin],
};
</script>
