<template>
  <svg
    viewBox="0 0 38 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   
  >
  <g v-if="!active">
    <path
      d="M8.91003 24.58H9.91003V23.11H8.84003V22.27C9.50775 22.0892 10.1227 21.7517 10.6339 21.2856C11.1451 20.8194 11.5377 20.2381 11.7792 19.5899C12.0207 18.9416 12.1041 18.2451 12.0225 17.5582C11.9409 16.8712 11.6967 16.2136 11.31 15.64C10.8154 14.9214 10.1197 14.3648 9.31003 14.04V9.77H8.68003V8.36H8.08003L7.68003 0L7.27003 8.36H6.69003V9.8H6.05003V14.06C5.20277 14.3987 4.48484 14.9975 3.99963 15.7702C3.51442 16.543 3.28697 17.4497 3.35003 18.36C3.41404 19.2713 3.76399 20.1392 4.35003 20.84C4.94026 21.5403 5.73348 22.04 6.62003 22.27V23.13H5.56003V24.58H6.56003L6.32003 29.27L6.12003 32.47L7.03003 31.74C7.22077 31.5895 7.45706 31.5084 7.70003 31.51C7.94274 31.5099 8.17853 31.5909 8.37003 31.74L9.37003 32.53L9.18003 29.27L8.91003 24.58Z"
      fill="#C4C4C4"
    />
    <path
      d="M35.86 49.6499V26.5899H22.86V49.6499H21.86V26.0899C21.86 25.9573 21.9127 25.8301 22.0064 25.7364C22.1002 25.6426 22.2274 25.5899 22.36 25.5899H29.99V18.6799H13.05V35.5399L15.91 37.8599C16.0816 37.9996 16.207 38.1878 16.27 38.3999C16.3424 38.6106 16.3424 38.8393 16.27 39.0499C16.1996 39.2582 16.0625 39.4374 15.88 39.5599C15.7042 39.6918 15.4898 39.7621 15.27 39.7599H14.78V49.6499H13.69V38.6499H15.25L13.05 36.8899L11.47 35.6199L9.47 33.9899L7.72 32.5899L6.05 33.9299L3.94 35.6199L0.18 38.6199H1.73V49.6199H0V52.4599H37.27V49.6499H35.86ZM28.68 48.4299H24.96V46.3299H28.68V48.4299ZM28.68 44.8899H24.96V42.7999H28.68V44.8899ZM28.68 41.3599H24.96V39.2699H28.68V41.3599ZM28.68 37.8199H24.96V35.7299H28.68V37.8199ZM28.68 34.2899H24.96V32.1999H28.68V34.2899ZM28.68 30.7599H24.96V28.6699H28.68V30.7599ZM33.68 48.4299H30V46.3299H33.73L33.68 48.4299ZM33.68 44.8899H30V42.7999H33.73L33.68 44.8899ZM33.68 41.3599H30V39.2699H33.73L33.68 41.3599ZM33.68 37.8199H30V35.7299H33.73L33.68 37.8199ZM33.68 34.2899H30V32.1999H33.73L33.68 34.2899ZM33.68 30.7599H30V28.6699H33.73L33.68 30.7599Z"
      fill="#3F4355"
    />
    </g>
    <g v-if="active">
      <path d="M8.91003 24.58H9.91003V23.11H8.84003V22.27C9.50775 22.0892 10.1227 21.7517 10.6339 21.2856C11.1451 20.8194 11.5377 20.2381 11.7792 19.5899C12.0207 18.9416 12.1041 18.2451 12.0225 17.5582C11.9409 16.8712 11.6967 16.2136 11.31 15.64C10.8154 14.9214 10.1197 14.3648 9.31003 14.04V9.77H8.68003V8.36H8.08003L7.68003 0L7.27003 8.36H6.69003V9.8H6.05003V14.06C5.20277 14.3987 4.48484 14.9975 3.99963 15.7702C3.51442 16.543 3.28697 17.4497 3.35003 18.36C3.41404 19.2713 3.76399 20.1392 4.35003 20.84C4.94026 21.5403 5.73348 22.04 6.62003 22.27V23.13H5.56003V24.58H6.56003L6.32003 29.27L6.12003 32.47L7.03003 31.74C7.22077 31.5895 7.45706 31.5084 7.70003 31.51C7.94274 31.5099 8.17853 31.5909 8.37003 31.74L9.37003 32.53L9.18003 29.27L8.91003 24.58Z" fill="#F9C697"/>
      <path d="M35.86 49.6499V26.5899H22.86V49.6499H21.86V26.0899C21.86 25.9573 21.9127 25.8301 22.0064 25.7364C22.1002 25.6426 22.2274 25.5899 22.36 25.5899H29.99V18.6799H13.05V35.5399L15.91 37.8599C16.0816 37.9996 16.207 38.1878 16.27 38.3999C16.3424 38.6106 16.3424 38.8393 16.27 39.0499C16.1996 39.2582 16.0625 39.4374 15.88 39.5599C15.7042 39.6918 15.4898 39.7621 15.27 39.7599H14.78V49.6499H13.69V38.6499H15.25L13.05 36.8899L11.47 35.6199L9.47 33.9899L7.72 32.5899L6.05 33.9299L3.94 35.6199L0.18 38.6199H1.73V49.6199H0V52.4599H37.27V49.6499H35.86ZM28.68 48.4299H24.96V46.3299H28.68V48.4299ZM28.68 44.8899H24.96V42.7999H28.68V44.8899ZM28.68 41.3599H24.96V39.2699H28.68V41.3599ZM28.68 37.8199H24.96V35.7299H28.68V37.8199ZM28.68 34.2899H24.96V32.1999H28.68V34.2899ZM28.68 30.7599H24.96V28.6699H28.68V30.7599ZM33.68 48.4299H30V46.3299H33.73L33.68 48.4299ZM33.68 44.8899H30V42.7999H33.73L33.68 44.8899ZM33.68 41.3599H30V39.2699H33.73L33.68 41.3599ZM33.68 37.8199H30V35.7299H33.73L33.68 37.8199ZM33.68 34.2899H30V32.1999H33.73L33.68 34.2899ZM33.68 30.7599H30V28.6699H33.73L33.68 30.7599Z" fill="#F38D2F"/>
    </g>
  </svg>


</template>

<script>
export default {
  name: "TinyCityIcon",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
