<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
     d="M33,11.39A21.61,21.61,0,1,0,54.61,33,21.64,21.64,0,0,0,33,11.39Zm16.44,22.2-12.35,2,1.59,2.21a.62.62,0,0,1-.06.78.63.63,0,0,1-.43.17.66.66,0,0,1-.35-.11l-2.22-1.59-2,12.35a.6.6,0,0,1-1.18,0l-2-12.35-2.22,1.59a.6.6,0,0,1-.84-.84l1.59-2.21-12.35-2a.6.6,0,0,1,0-1.18l12.35-2-1.59-2.21a.6.6,0,0,1,.84-.84l2.22,1.59,2-12.35a.6.6,0,0,1,1.18,0l2,12.35,2.22-1.59a.6.6,0,0,1,.84.84l-1.59,2.21,12.35,2a.6.6,0,0,1,0,1.18Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Shadow",
  mixins:[SCGMixin],
};
</script>