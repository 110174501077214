<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
       fill-rule="evenodd"
      d="M17,13.5v35.7h32V13.5H17z M33.1,44.5c-7.2-0.1-13-5.9-13-13.1c0-7.2,5.8-13,13.1-13c7.2,0,13,5.9,13,13
		C46.2,38.7,40.2,44.6,33.1,44.5z M41.4,49.9c2.6,0,5,0,7.6,0c-0.5,1.1-0.4,2.5-2.2,2.5C42.4,52.6,42.4,52.6,41.4,49.9z M17.1,49.9
		c2.6,0,5,0,7.6,0c-0.5,1.1-0.4,2.5-2.2,2.5C18.1,52.6,18.1,52.6,17.1,49.9z M26.5,41.8c-2.2-3.2-1.2-5.6,0.4-8c-2.1,0-3.8,0-5.5,0
		c-0.1-2.6,1.1-3.7,5.6-5.1c-1.4-1.3-2.8-2.4-4.1-3.6c2.2-2.1,3.8-2,7.8,0.2c0-1,0-1.9,0-2.7c0-0.9,0-1.8,0-2.7c2.6-0.1,4,1.5,5,6
		c1.3-1.8,2.5-3.2,3.7-4.9c2.3,3.2,1.3,5.5-0.4,8c2,0,3.7,0,5.5,0c0.1,2.7-1.2,3.9-5.8,5.2c1.6,1.3,3,2.4,4.4,3.5
		c-2.1,2.1-3,2.1-7.7,0.1c0,1.8,0,3.5,0,5.3c-2.7,0.1-3.9-1.2-5.2-6c-0.8,1-1.3,1.7-1.9,2.4C27.8,40.1,27.3,40.8,26.5,41.8z
		 M33.1,27.7c-1.9,0-3.7,1.6-3.8,3.5C29.3,33.2,31,35,33,35c2,0,3.6-1.5,3.6-3.6C36.7,29.4,35.1,27.7,33.1,27.7z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Zelt",
  mixins:[SCGMixin],
};
</script>