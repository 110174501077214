<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
     d="M43.17,51.25h-9V43.57a7.54,7.54,0,0,0,3.51-2.2A13.58,13.58,0,0,0,39,39.82l.28-.41.13-.06a12.2,12.2,0,0,0,1.13-.55,10.83,10.83,0,0,0,3-2.28,6.11,6.11,0,0,0,1.67-4.24,8.88,8.88,0,0,0-2.35-5.4q0-.16,0-.39a8.28,8.28,0,0,0-.1-2.08,7.29,7.29,0,0,0-3-4.85c0-.15,0-.32-.06-.52a9.41,9.41,0,0,0-.61-2.32,5.93,5.93,0,0,0-5.17-3.92l-.1,0-.23,0a6.49,6.49,0,0,0-.77,0,6.06,6.06,0,0,0-2.34.57A5.42,5.42,0,0,0,28,15.53,8.47,8.47,0,0,0,27,19.38l-.24.14a7.14,7.14,0,0,0-1.64,1.37,6.43,6.43,0,0,0-1.4,5.8l-.24.26c-.28.29-.61.64-.93,1a7.46,7.46,0,0,0-1.59,3.17,5.22,5.22,0,0,0,1,4.24,13.94,13.94,0,0,0,5.22,3.93l.17.36a9.41,9.41,0,0,0,1,1.59,5.85,5.85,0,0,0,3.86,2.43v7.54h-8.5a1,1,0,0,0,0,2h19.5a1,1,0,0,0,0-2ZM29.38,35.74a6.87,6.87,0,0,1-2.2-5.31,1,1,0,0,1,2,0,4.93,4.93,0,0,0,1.54,3.83,5.08,5.08,0,0,0,1.46.9V28.82a6.45,6.45,0,0,1-2.46-1.4,6.28,6.28,0,0,1-2-4.82,1,1,0,0,1,2,0A4.34,4.34,0,0,0,31.07,26a4.58,4.58,0,0,0,1.11.73V20.8a1,1,0,0,1,2,0V31.71A4.15,4.15,0,0,0,35.69,31a4.24,4.24,0,0,0,1.49-3.55,1,1,0,0,1,2,0,6.13,6.13,0,0,1-2.26,5.12,6.22,6.22,0,0,1-2.74,1.21v8l-2,0V37.27A7,7,0,0,1,29.38,35.74Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Shadow",
  mixins:[SCGMixin],
};
</script>