<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
      d="M49.24,44.06a5.64,5.64,0,0,1-4.35-2A6.38,6.38,0,0,1,43.6,39.8H22.9a6.87,6.87,0,0,1-.84,1.75,5.69,5.69,0,0,1-5,2.51,5.35,5.35,0,0,1-4.25-2,7.68,7.68,0,0,1-1.27-2.26H9.69a3,3,0,0,1-2.19-1,2.68,2.68,0,0,1-.65-2.1v-5.6a2.84,2.84,0,0,1,2.84-2.84h4.72l.84-.73c.95-.8,1.88-1.55,2.76-2.21a30.06,30.06,0,0,1,3.39-2.26,7.27,7.27,0,0,1,3.33-1.13c1.15,0,3.42,0,5.39.08l3.64.07a11.68,11.68,0,0,1,6.58,2.07l7.36,4.6c4.37.26,6.81.82,8.38,1.92,1.77,1.25,2.33,3.16,2.93,5.18l0,.07a3.75,3.75,0,0,1-.17,2.55,2.63,2.63,0,0,1-2.48,1.32h-1.2a6.64,6.64,0,0,1-1.4,2.31A6,6,0,0,1,49.24,44.06ZM22.13,38.8H44.39l.09.4a5.16,5.16,0,0,0,1.16,2.23,4.68,4.68,0,0,0,3.6,1.63,5.07,5.07,0,0,0,3.81-1.63,5.63,5.63,0,0,0,1.28-2.26l.1-.37h2A1.63,1.63,0,0,0,58,38a2.84,2.84,0,0,0,.09-1.87v0c-.66-2.21-1.13-3.62-2.55-4.62s-3.73-1.51-8-1.75h-.12L39.81,25a10.64,10.64,0,0,0-6.06-1.92L30.1,23c-2,0-4.22-.08-5.37-.08a6.51,6.51,0,0,0-2.84,1,31.82,31.82,0,0,0-3.28,2.18c-.87.66-1.78,1.39-2.71,2.19l-.83.71-.24.21-.24,0H9.69a1.86,1.86,0,0,0-1.84,1.84v5.66a1.7,1.7,0,0,0,.4,1.38,2,2,0,0,0,1.44.65H12.3l.11.35a6.79,6.79,0,0,0,1.2,2.27,4.33,4.33,0,0,0,3.48,1.64A4.73,4.73,0,0,0,21.23,41a5.73,5.73,0,0,0,.8-1.81Z"
    />
  </svg>
</template>
​
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "EAutoFalse",
  mixins:[SCGMixin],
};
</script>