<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
      d="M42.93,13.52H23.07a5.3,5.3,0,0,0-5.3,5.3V43.67a5.3,5.3,0,0,0,4,5.13V52a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-3a2.4,2.4,0,0,0,.28,0H25.2v3a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V49h2.41v3a.5.5,0,1,0,1,0V49H32v3a.5.5,0,0,0,.5.5A.5.5,0,0,0,33,52V49h2.42v3a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V49h6.49a5.31,5.31,0,0,0,5.3-5.3V18.82A5.3,5.3,0,0,0,42.93,13.52Zm.46,29.34a1.27,1.27,0,0,1-1.27,1.27H35.73a1.27,1.27,0,0,1-1.27-1.27V40.64a1.27,1.27,0,0,1,1.27-1.27h6.39a1.27,1.27,0,0,1,1.27,1.27Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "PvGeraete",
  mixins:[SCGMixin],
};
</script>