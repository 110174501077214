<template>
  <svg viewBox="0 0 26 45" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.4159 20.5565C25.3923 21.5328 25.3923 23.1157 24.4159 24.092L4.09181 44.4161L0.556278 40.8806L19.1126 22.3242L0.556281 3.76785L4.09182 0.232315L24.4159 20.5565Z"
      :fill="disabled ? '#cccccc' :'#303448'"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowLeft",
  props:{
    disabled:{
      type: Boolean,
      default : false
    }
  }
};
</script>
