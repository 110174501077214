<template>
<svg viewBox="0 0 18 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g v-if="!active">
<path d="M9.70006 8.78011C10.2755 8.78496 10.846 8.67315 11.3771 8.45144C11.9082 8.22972 12.3888 7.9027 12.7901 7.49011C13.2105 7.09373 13.5454 6.61562 13.7744 6.08511C14.0033 5.55461 14.1214 4.98291 14.1214 4.40511C14.1214 3.82731 14.0033 3.25561 13.7744 2.7251C13.5454 2.1946 13.2105 1.71648 12.7901 1.32011C12.388 0.902421 11.9057 0.570155 11.3722 0.343201C10.8387 0.116246 10.2648 -0.000732422 9.68506 -0.000732422C9.10527 -0.000732422 8.53145 0.116246 7.99793 0.343201C7.46441 0.570155 6.98216 0.902421 6.58006 1.32011C6.17459 1.72505 5.85292 2.20594 5.63346 2.73529C5.41399 3.26464 5.30103 3.83207 5.30103 4.40511C5.30103 4.97815 5.41399 5.54557 5.63346 6.07493C5.85292 6.60428 6.17459 7.08517 6.58006 7.49011C6.98476 7.90639 7.47028 8.23555 8.00681 8.45739C8.54334 8.67922 9.11955 8.78904 9.70006 8.78011Z" fill="#DCDCDC"/>
<path d="M15.25 29.38C14.8677 30.7045 14.1634 31.9139 13.2 32.9C12.4313 33.7089 11.3752 34.183 10.26 34.22C9.63769 34.2711 9.01583 34.1166 8.48996 33.78C8.30372 33.5604 8.16349 33.3056 8.0776 33.0307C7.99171 32.7559 7.96191 32.4666 7.98996 32.18C7.96914 31.907 7.96914 31.6329 7.98996 31.36C8.03071 31.044 8.08745 30.7302 8.15996 30.42L12.16 11.72H4.15996L0.439957 29.38C0.299957 30.0466 0.189957 30.7133 0.109957 31.38C0.0425117 31.9074 0.00578251 32.4383 -4.31212e-05 32.97C-0.105651 34.7386 0.446411 36.4838 1.54996 37.87C2.11832 38.4475 2.80235 38.8984 3.55718 39.1931C4.31201 39.4878 5.12061 39.6197 5.92996 39.58C8.4604 39.5866 10.9039 38.6569 12.79 36.97C15.0156 34.9 16.6602 32.2832 17.56 29.38H15.25Z" fill="#3F4355"/>
</g>

<g v-if="active">

<path d="M9.70006 8.78011C10.2755 8.78496 10.846 8.67315 11.3771 8.45144C11.9082 8.22972 12.3888 7.9027 12.7901 7.49011C13.2105 7.09373 13.5454 6.61562 13.7744 6.08511C14.0033 5.55461 14.1214 4.98291 14.1214 4.40511C14.1214 3.82731 14.0033 3.25561 13.7744 2.7251C13.5454 2.1946 13.2105 1.71648 12.7901 1.32011C12.388 0.902421 11.9057 0.570155 11.3722 0.343201C10.8387 0.116246 10.2648 -0.000732422 9.68506 -0.000732422C9.10527 -0.000732422 8.53145 0.116246 7.99793 0.343201C7.46441 0.570155 6.98216 0.902421 6.58006 1.32011C6.17459 1.72505 5.85292 2.20594 5.63346 2.73529C5.41399 3.26464 5.30103 3.83207 5.30103 4.40511C5.30103 4.97815 5.41399 5.54557 5.63346 6.07493C5.85292 6.60428 6.17459 7.08517 6.58006 7.49011C6.98476 7.90639 7.47028 8.23555 8.00681 8.45739C8.54334 8.67922 9.11955 8.78904 9.70006 8.78011Z" fill="#F9C697"/>
<path d="M15.25 29.38C14.8677 30.7045 14.1634 31.9139 13.2 32.9C12.4313 33.7089 11.3752 34.183 10.26 34.22C9.63769 34.2711 9.01583 34.1166 8.48996 33.78C8.30372 33.5604 8.16349 33.3056 8.0776 33.0307C7.99171 32.7559 7.96191 32.4666 7.98996 32.18C7.96914 31.907 7.96914 31.6329 7.98996 31.36C8.03071 31.044 8.08745 30.7302 8.15996 30.42L12.16 11.72H4.15996L0.439957 29.38C0.299957 30.0466 0.189957 30.7133 0.109957 31.38C0.0425117 31.9074 0.00578251 32.4383 -4.31212e-05 32.97C-0.105651 34.7386 0.446411 36.4838 1.54996 37.87C2.11832 38.4475 2.80235 38.8984 3.55718 39.1931C4.31201 39.4878 5.12061 39.6197 5.92996 39.58C8.4604 39.5866 10.9039 38.6569 12.79 36.97C15.0156 34.9 16.6602 32.2832 17.56 29.38H15.25Z" fill="#F49131"/>
</g>
</svg>


</template>

<script>
export default {
  name: "ExplorerIcon",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
