<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
       fill-rule="evenodd"
      d="M50.6,26.64,33.48,35.15V11.05ZM16,45,32.43,55V36.25L16,27.62ZM33.43,55,50,45.37V28.08L33.43,36.31Zm-1-19.88,0-.11V11L15.4,26.15Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Zelt",
  mixins:[SCGMixin],
};
</script>