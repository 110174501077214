<template>
  <svg viewBox="-75 -60 350 350" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M87.6298 223.906C82.6839 220.576 78.8985 215.787 76.7998 210.206C72.9698 200.866 68.6998 191.716 64.7998 182.396C63.2498 178.656 60.7998 176.916 56.8698 175.896C52.9398 174.876 49.3398 172.146 45.4298 170.616C43.5898 169.906 40.9998 169.506 39.3198 170.236C27.4598 175.436 17.6598 171.916 8.99984 163.476C-0.150161 154.566 -1.79016 143.766 1.68984 131.886C3.63984 125.256 7.84983 120.946 14.3698 118.276C37.2698 108.916 59.2698 97.8758 78.9698 82.6458C99.4698 66.7958 113.04 46.7658 117.44 20.9158C118.044 17.5053 118.961 14.1577 120.18 10.9158C123.89 0.915799 132.18 -2.7842 141.59 2.2158C147.549 5.44891 153.006 9.53166 157.79 14.3358C175.06 31.5458 186.97 52.3358 196.61 74.5558C205.36 94.6958 211.88 115.556 213.38 137.556C213.959 145.799 213.462 154.082 211.9 162.196C209.59 173.846 200.51 177.946 189.64 172.976C184.445 170.412 179.415 167.527 174.58 164.336C154.58 151.956 132.84 148.006 109.73 151.176C99.2198 152.616 96.0699 158.506 100.21 168.286C102.28 173.156 104.63 177.916 106.51 182.856C109.09 189.666 112.51 195.366 119.19 199.286C125.6 203.046 124.11 210.886 116.87 213.866C108.48 217.326 100.01 220.566 91.5799 223.866L87.6298 223.906ZM192.54 136.376C192.2 132.556 192.03 129.066 191.54 125.626C187.54 96.6258 176.07 70.6258 158.73 47.1958C153.64 40.3358 147.37 34.2858 141.22 28.2958C138.15 25.2958 136.22 26.1858 135.66 30.4158C134.966 35.0044 134.715 39.6491 134.91 44.2858C136.97 76.9758 147.91 106.366 168.83 131.686C173.93 137.866 180.5 142.856 186.59 148.166C189.29 150.516 191.27 149.166 191.74 145.946C192.21 142.726 192.31 139.346 192.54 136.376Z"
      :fill="GetState"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin";
export default {
  name: "MieterstromInitiieren",
  mixins: [SCGMixin],
};
</script>
