<template>
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="GetState"
	   fill-rule="evenodd"
      d="M11.06,32.75A21.89,21.89,0,0,1,33.22,11.07c11.82,0,21.79,9.8,21.72,21.5-.08,13-9.6,22.35-22.07,22.36C20.61,54.93,11.1,45.1,11.06,32.75Zm3.11.37A18.83,18.83,0,1,0,33,14.21,18.89,18.89,0,0,0,14.17,33.12Zm36-.2A17.25,17.25,0,0,1,36.86,49.68a2.9,2.9,0,0,1-.59.09A.88.88,0,0,1,35.22,49a10.55,10.55,0,0,1-.11-1.25,12.08,12.08,0,0,0-.1-1.36,1.91,1.91,0,0,0-2.27-1.7,1.86,1.86,0,0,0-1.75,2c0,.74-.06,1.48-.17,2.21-.13.88-.5,1.13-1.37.92A16.6,16.6,0,0,1,24,47.64,17,17,0,0,1,16,35.28,17.26,17.26,0,0,1,29.6,16.15c1-.2,1.42.15,1.39,1.19,0,.72,0,1.45,0,2.17a1.85,1.85,0,0,0,1.81,1.82A1.77,1.77,0,0,0,34.89,20a13.47,13.47,0,0,0,.21-2.3,4.1,4.1,0,0,0,0-.5c0-.86.38-1.23,1.2-1a24.39,24.39,0,0,1,3.88,1.25A16.87,16.87,0,0,1,50,30.66C50.11,31.4,50.11,32.16,50.17,32.92ZM24.43,35.74A2.83,2.83,0,0,0,27.23,33a2.92,2.92,0,0,0-2.81-2.8A2.8,2.8,0,0,0,21.7,33,2.7,2.7,0,0,0,24.43,35.74Zm17.11,0a2.85,2.85,0,0,0,2.84-2.65,3,3,0,0,0-2.85-2.89,2.82,2.82,0,0,0-2.72,2.87A2.71,2.71,0,0,0,41.54,35.74Z"
    />
  </svg>
</template>
<script>
import SCGMixin from "../../Mixins/SVGMixin"
export default {
  name: "Alltagsverbrauch",
  mixins:[SCGMixin],
};
</script>