<template>
  
<svg viewBox="5 -10 213 213" fill="none" xmlns="http://www.w3.org/2000/svg">


<g clip-path="url(#clip0)" filter="url(#filter0_di)">

<g :class="'svg-link svg-link-active-' + ($route.name == 'Configurator')" @click="e => ChangeRoute('configurator')" title="Konfigurator">
  <path class="bg" d="M105.5 27.1C75.6 9.9 40.9 0.1 4 0V89C24.8 89 44.2 94.5 61.1 104.1L105.5 27.1Z" fill="white"/>
  <path d="M65.4544 46.0025C65.1899 45.5955 64.8758 45.3676 64.5782 45.3676H62.3464C61.9496 45.3676 61.6024 45.0908 61.5198 44.7164C60.8089 41.3302 59.0399 38.3185 56.3948 35.9905C56.0972 35.73 56.031 35.3068 56.2294 34.9649L57.3536 33.0602C57.6843 32.4904 57.056 31.3019 55.7335 30.5531C54.5266 29.8693 53.0552 29.8531 52.7081 30.4228L51.5839 32.3276C51.3855 32.6694 50.9722 32.816 50.5919 32.702C47.2855 31.6275 43.6649 31.6275 40.3585 32.702C39.9782 32.816 39.5649 32.6694 39.3665 32.3276L38.2589 30.4066C37.9282 29.8368 36.4403 29.8531 35.25 30.5368C33.9274 31.2857 33.2992 32.4904 33.6298 33.0439L34.754 34.9486C34.9524 35.2905 34.8863 35.7137 34.5887 35.9742C31.9435 38.3185 30.1746 41.3302 29.4637 44.7164C29.381 45.1071 29.0339 45.3676 28.6371 45.3676H26.4052C25.744 45.3676 25 46.4909 25 48.0049C25 49.5189 25.744 50.6422 26.4052 50.6422H28.6371C29.0339 50.6422 29.381 50.919 29.4637 51.2934C30.1746 54.6796 31.9435 57.6913 34.5887 60.0193C34.8863 60.2798 34.9524 60.7031 34.754 61.0449L33.6298 62.9497C33.2992 63.5195 33.9274 64.7079 35.25 65.4567C36.4569 66.1405 37.9282 66.1568 38.2754 65.587L39.3831 63.6985C39.5815 63.3567 39.9948 63.2101 40.375 63.3241C43.6815 64.3986 47.302 64.3986 50.6085 63.3241C50.6911 63.2915 50.7903 63.2753 50.873 63.2753C51.1706 63.2753 51.4516 63.4218 51.6004 63.6823L52.7246 65.587C53.0718 66.1731 54.5431 66.1405 55.75 65.4567C57.0726 64.7079 57.7008 63.5032 57.3702 62.9497L56.246 61.0449C56.0476 60.7031 56.1137 60.2798 56.4113 60.0193C59.0399 57.6913 60.8089 54.6796 61.5363 51.2934C61.619 50.9027 61.9661 50.6422 62.3629 50.6422H64.5948C65.256 50.6422 66 49.5189 66 48.0049C65.9669 47.256 65.7851 46.5235 65.4544 46.0025ZM45.4835 57.9518C39.8129 57.9518 35.2169 53.4261 35.2169 47.8421C35.2169 42.2582 39.8129 37.7324 45.4835 37.7324C51.154 37.7324 55.75 42.2582 55.75 47.8421C55.75 53.4261 51.154 57.9518 45.4835 57.9518Z" fill="#3F4355"/>
</g>

<g :class="'svg-link svg-link-active-' + ($route.name == 'Dashboard')" @click="e => ChangeRoute('dashboard')" title="Dashboard">
  <path class="bg" d="M103.7 146.3L180.8 101.8C162.8 71.1001 137.1 45.4001 106.3 27.6001L61.9004 104.6C79.2004 114.6 93.6004 129.1 103.7 146.3Z" fill="white"/>
  <g clip-path="url(#clip1)">
    <path fill="#F49131" class="hover-orange" d="M93 93.4V91.2C93 89.2 94.7 87.5 96.7 87.5H124.7C126.7 87.5 128.4 89.2 128.4 91.2V93.4C128.4 95.4 126.7 97.1 124.7 97.1H96.7C94.7 97.1 93 95.4 93 93.4Z"/>
    <path fill="#31C5F4" class="hover-light-blue" d="M107.5 97.1H96.7C94.7 97.1 93 95.4 93 93.4V91.2C93 89.2 94.7 87.5 96.7 87.5H107.5V97.1Z"/>
    <path fill="#F49131" class="hover-orange" d="M102.1 105.8V103.6C102.1 101.6 103.8 99.8999 105.8 99.8999H133.8C135.8 99.8999 137.5 101.6 137.5 103.6V105.8C137.5 107.8 135.8 109.5 133.8 109.5H105.8C103.8 109.5 102.1 107.9 102.1 105.8Z"/>
    <path fill="#F9C697" class="hover-light-orange" d="M107.7 109.5H105.8C103.8 109.5 102.1 107.8 102.1 105.8V103.6C102.1 101.6 103.8 99.8999 105.8 99.8999H107.8L107.7 109.5Z" />
    <path fill="#F49131" class="hover-orange" d="M102.1 80.9V78.7C102.1 76.7 103.8 75 105.8 75H133.8C135.8 75 137.5 76.7 137.5 78.7V80.9C137.5 82.9 135.8 84.6 133.8 84.6H105.8C103.8 84.6 102.1 83 102.1 80.9Z" />
    <path fill="#F9C697" class="hover-light-orange" d="M107.7 84.6H105.8C103.8 84.6 102.1 82.9 102.1 80.9V78.7C102.1 76.7 103.8 75 105.8 75H107.8L107.7 84.6Z" />
  </g>
</g>


<g :class="'svg-link svg-link-active-' + ($route.name == 'ExplorerSingle' || $route.name == 'Explorer')"  @click="e => ChangeRoute('explore')" title="Explore">
<path class="bg" d="M181.299 102.7L104.199 147.2C113.799 164 119.299 183.5 119.299 204.3H208.799C208.599 167.3 198.599 132.6 181.299 102.7Z" fill="white"/>
<path d="M162.871 150.006C162.871 151.107 162.467 152.042 161.659 152.809C160.885 153.577 159.943 153.961 158.833 153.961C157.722 153.961 156.78 153.577 156.006 152.809C155.232 152.042 154.845 151.107 154.845 150.006C154.845 148.904 155.232 147.969 156.006 147.202C156.78 146.401 157.722 146 158.833 146C159.943 146 160.885 146.401 161.659 147.202C162.467 147.969 162.871 148.904 162.871 150.006ZM150 175.992C150 175.558 150.034 175.074 150.101 174.54C150.168 173.972 150.269 173.371 150.404 172.737L153.836 156.665H161.104L157.47 173.688C157.403 173.989 157.352 174.273 157.319 174.54C157.285 174.773 157.268 175.024 157.268 175.291C157.268 175.958 157.42 176.442 157.722 176.743C158.059 177.01 158.597 177.143 159.338 177.143C160.28 177.143 161.171 176.743 162.013 175.942C162.854 175.107 163.476 174.039 163.88 172.737H166C164.923 175.775 163.476 178.078 161.659 179.647C159.842 181.216 157.756 182 155.401 182C153.718 182 152.389 181.483 151.413 180.448C150.471 179.38 150 177.894 150 175.992Z" fill="#3F4355"/>
</g>
</g>
<defs>
<filter id="filter0_di" x="0" y="0" width="212.8" height="212.3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow"/>
</filter>
<clipPath id="clip0">
<rect width="204.8" height="204.3" fill="white" transform="translate(4)"/>
</clipPath>
<clipPath id="clip1">
<rect width="44.5" height="34.5" fill="white" transform="translate(93 75)"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
  name : "MainMenuSVG",
  mounted(){

  },
  methods: {
    ChangeRoute(route){

      this.$store.state.audio.controller.PlaySound("menu.click");
      if(!this.$route.path.includes(route)){
        this.$router.push('/desktop/'+this.$router.currentRoute.params.area+"/"+ route);
      }
    }

  }
}
</script>

<style lang="scss">
.svg-link{
  
  .bg,path{
    transition-duration: .2s;
  }

  .hover-orange{fill:#21232c}
  .hover-light-orange{fill:#5f616b}
  .hover-light-blue{fill:#818594}



  &:hover, &.svg-link-active-true{
    cursor: pointer;
    z-index: 1;

    path{
      fill:#fff;
    }

    .bg{
      fill:#3F4355;
    }
    
    .hover-orange{fill:#F49131}
    .hover-light-orange{fill:#F9C697}
    .hover-light-blue{fill:#31C5F4}


  }
}


</style>