<template>
<svg viewBox="0 0 49 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g v-if="!active">
<path d="M0 20.21V17.8C0.0129185 16.7263 0.444506 15.7001 1.20284 14.9399C1.96118 14.1796 2.98634 13.7455 4.06 13.73H34.78C35.8546 13.7455 36.8808 14.1793 37.6408 14.9392C38.4007 15.6991 38.8345 16.7254 38.85 17.8V20.21C38.8345 21.2846 38.4007 22.3108 37.6408 23.0708C36.8808 23.8307 35.8546 24.2645 34.78 24.28H4.06C2.98634 24.2644 1.96118 23.8303 1.20284 23.0701C0.444506 22.3099 0.0129185 21.2837 0 20.21Z" fill="#3F4355"/>
<path d="M15.93 24.28H4.06C2.98634 24.2644 1.96118 23.8303 1.20284 23.0701C0.444506 22.3099 0.0129185 21.2837 0 20.21V17.8C0.0129185 16.7263 0.444506 15.7001 1.20284 14.9399C1.96118 14.1796 2.98634 13.7455 4.06 13.73H15.93V24.28Z" fill="#DCDCDC"/>
<path d="M9.98999 33.8401V31.4201C10.0055 30.3464 10.4397 29.3213 11.1999 28.5629C11.9601 27.8046 12.9863 27.373 14.06 27.3601H44.78C45.8537 27.373 46.8799 27.8046 47.6401 28.5629C48.4003 29.3213 48.8344 30.3464 48.85 31.4201V33.8401C48.8319 34.913 48.397 35.9367 47.6373 36.6945C46.8777 37.4523 45.8529 37.8847 44.78 37.9001H14.06C13.526 37.9014 12.997 37.7974 12.5032 37.5939C12.0095 37.3905 11.5607 37.0916 11.1827 36.7145C10.8046 36.3374 10.5047 35.8893 10.3 35.3961C10.0953 34.9029 9.98999 34.3741 9.98999 33.8401Z" fill="#3F4355"/>
<path d="M16.15 37.9001H14.06C12.9871 37.8847 11.9623 37.4523 11.2027 36.6945C10.443 35.9367 10.0081 34.913 9.98999 33.8401V31.4201C10.0055 30.3464 10.4397 29.3213 11.1999 28.5629C11.9601 27.8046 12.9863 27.373 14.06 27.3601H16.26L16.15 37.9001Z" fill="#DCDCDC"/>
<path d="M9.99 6.48001V4.07001C10.0081 2.99715 10.443 1.97344 11.2027 1.21564C11.9623 0.457842 12.9871 0.0254461 14.06 0.0100098H44.78C45.8599 0.0150842 46.8948 0.442901 47.663 1.2018C48.4313 1.96069 48.8717 2.9903 48.89 4.07001V6.49001C48.8717 7.56972 48.4313 8.59933 47.663 9.35822C46.8948 10.1171 45.8599 10.5449 44.78 10.55H14.06C13.5252 10.5513 12.9953 10.447 12.5009 10.2429C12.0065 10.0388 11.5573 9.73907 11.1791 9.36088C10.8009 8.98268 10.5012 8.53348 10.2971 8.03909C10.0931 7.5447 9.98868 7.01486 9.99 6.48001Z" fill="#3F4355"/>
<path d="M16.15 10.55H14.06C12.9854 10.5345 11.9591 10.1007 11.1992 9.34079C10.4393 8.58085 10.0055 7.55461 9.98999 6.48001V4.07001C10.0081 2.99715 10.443 1.97344 11.2027 1.21564C11.9623 0.457842 12.9871 0.0254461 14.06 0.0100098H16.26L16.15 10.55Z" fill="#DCDCDC"/>
</g>

<g v-if="active">
<path d="M0 20.21V17.8C0.0129185 16.7263 0.444503 15.7001 1.20284 14.9399C1.96118 14.1796 2.98634 13.7455 4.06 13.73H34.82C35.8946 13.7455 36.9208 14.1793 37.6808 14.9392C38.4407 15.6991 38.8745 16.7254 38.89 17.8V20.21C38.8745 21.2846 38.4407 22.3108 37.6808 23.0708C36.9208 23.8307 35.8946 24.2645 34.82 24.28H4.06C2.98634 24.2644 1.96118 23.8303 1.20284 23.0701C0.444503 22.3099 0.0129185 21.2837 0 20.21V20.21Z" fill="#F49131"/>
<path d="M15.93 24.28H4.06C2.98634 24.2644 1.96118 23.8303 1.20284 23.0701C0.444503 22.3099 0.0129185 21.2837 0 20.21V17.8C0.0129185 16.7263 0.444503 15.7001 1.20284 14.9399C1.96118 14.1796 2.98634 13.7455 4.06 13.73H15.93V24.28Z" fill="#31C5F4"/>
<path d="M9.99005 33.8401V31.4201C10.0056 30.3455 10.4393 29.3193 11.1993 28.5593C11.9592 27.7994 12.9855 27.3656 14.0601 27.3501H44.8201C45.8947 27.3656 46.9209 27.7994 47.6808 28.5593C48.4408 29.3193 48.8746 30.3455 48.8901 31.4201V33.8401C48.8849 34.9217 48.4557 35.958 47.6947 36.7266C46.9337 37.4951 45.9015 37.9344 44.8201 37.9501H14.0601C13.5218 37.9515 12.9887 37.8458 12.4916 37.6392C11.9946 37.4327 11.5436 37.1293 11.1649 36.7469C10.7862 36.3644 10.4873 35.9105 10.2856 35.4115C10.0839 34.9124 9.98342 34.3783 9.99005 33.8401V33.8401Z" fill="#F49131"/>
<path d="M16.15 37.9501H14.06C12.9871 37.9347 11.9623 37.5023 11.2026 36.7445C10.443 35.9867 10.0081 34.963 9.98999 33.8901V31.4201C10.0055 30.3455 10.4393 29.3193 11.1992 28.5593C11.9591 27.7994 12.9854 27.3656 14.06 27.3501H16.26L16.15 37.9501Z" fill="#F9C697"/>
<path d="M9.99 6.48V4.06C10.0081 2.98714 10.443 1.96343 11.2027 1.20563C11.9623 0.447831 12.9871 0.0154363 14.06 0H44.82C45.8929 0.0154363 46.9177 0.447831 47.6773 1.20563C48.437 1.96343 48.8719 2.98714 48.89 4.06V6.48C48.8745 7.5546 48.4407 8.58084 47.6808 9.34078C46.9208 10.1007 45.8946 10.5345 44.82 10.55H14.06C13.5252 10.5513 12.9953 10.4469 12.5009 10.2429C12.0065 10.0388 11.5573 9.73906 11.1791 9.36087C10.8009 8.98267 10.5012 8.53347 10.2971 8.03908C10.0931 7.54469 9.98868 7.01485 9.99 6.48V6.48Z" fill="#F49131"/>
<path d="M16.15 10.55H14.06C12.9854 10.5345 11.9591 10.1007 11.1992 9.34078C10.4393 8.58084 10.0055 7.5546 9.98999 6.48V4.06C10.0081 2.98714 10.443 1.96343 11.2026 1.20563C11.9623 0.447831 12.9871 0.0154363 14.06 0H16.26L16.15 10.55Z" fill="#F9C697"/>
</g>
</svg>



</template>


<script>
export default {
  name: "DashboardIcon",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
